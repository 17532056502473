import { Errors } from 'io-ts';
import { sendReport } from 'lib/sendReport';

type ReportTypeErrors = <T>(args: {
  id?: string;
  model: string;
  fallback: T;
}) => (errors: Errors) => T;

export const reportTypeErrors: ReportTypeErrors =
  ({ id, model, fallback }) =>
  () => {
    sendReport(`Schema mismatch in ${model}${id ? ` with ID ${id}` : ''}`);
    return fallback;
  };

import { sendWarningReport } from 'lib/sendReport';
import { generateRandomString } from 'lib/util';

async function fetchWithRetry(
  info: RequestInfo | URL,
  init?: RequestInit,
  maxAttempts = 3,
  delayMultiplier = 1000
): Promise<Response> {
  let currentAttempt = 1;
  const correlationToken = `[${generateRandomString(8)}]`;

  async function executeFetch(): Promise<Response> {
    try {
      const response = await fetch(info, init);

      if (response.status >= 500) {
        throw new Error(`${response.status} - ${response.statusText}`);
      }

      if (currentAttempt > 1) {
        sendWarningReport(
          `${correlationToken} - ${currentAttempt}/${maxAttempts} recovered - ${info}`
        );
      }

      return response;
    } catch (e) {
      const error = e as Error & { code?: string };

      sendWarningReport(
        `${correlationToken} - ${currentAttempt}/${maxAttempts} failed - ${info} - ${
          error.code ? `${error.code} -` : ''
        }${error.message}`
      );

      if (currentAttempt >= maxAttempts) {
        throw error;
      }

      await new Promise(resolve =>
        setTimeout(resolve, delayMultiplier * 2 ** currentAttempt)
      );

      currentAttempt++;

      return executeFetch();
    }
  }

  return executeFetch();
}

export default fetchWithRetry;

import contentfulResolveResponse from 'contentful-resolve-response';
import {
  IContentPage,
  IEditorialPlp,
  IFlashBanner,
  IFooter,
  INavigationTop,
  IPasswordProtectedRoutes,
  ISiteSettings,
  ISizeGuide,
  ISubscribePage,
  ITechnicalAspects,
} from 'generated/contentful';
import { getClientSideFeatureFlags } from 'lib/featureFlags';
import fetchWithRetry from 'lib/fetchWithRetry';
import { stringify } from 'qs';

export type ContentTypes =
  | IContentPage
  | IEditorialPlp
  | IFlashBanner
  | IFooter
  | INavigationTop
  | IPasswordProtectedRoutes
  | ISiteSettings
  | ITechnicalAspects
  | ISizeGuide
  | ISubscribePage;

export type ContentTypeId = ContentTypes['sys']['contentType']['sys']['id'];

export interface ResourceQueryArguments<T extends ContentTypes> {
  limit?: number;
  select?: string;
  type: ContentTypeId;
  query?: {
    [key in keyof T['fields']]?: string;
  };
}

interface ResourceQuery {
  access_token: string;
  content_type: ContentTypeId;
  include: number;
  limit: number;
  select?: string;
  [key: `fields.${string}`]: string | undefined;
}

const fetchResource = async <T extends ContentTypes>({
  type,
  limit = 100,
  select,
  query = {},
}: ResourceQueryArguments<T>): Promise<T[]> => {
  const contentfulHost = getClientSideFeatureFlags().isStaging
    ? 'https://preview.contentful.com'
    : 'https://cdn.contentful.com';

  const endpoint = [
    contentfulHost,
    'spaces',
    process.env.CONTENTFUL_SPACE_ID,
    'environments',
    process.env.CONTENTFUL_ENVIRONMENT,
    'entries',
  ].join('/');

  const queryParameters: ResourceQuery = {
    access_token: `${process.env.NEXT_PUBLIC_CONTENTFUL_CMS_TOKEN}`,
    content_type: type,
    include: 10,
    limit,
  };

  if (select) {
    queryParameters.select = `sys,${select}`;
  }

  for (const key in query) {
    const field = key as keyof typeof query;

    if (field === 'locale') {
      queryParameters['fields.locale[in]'] = `${query[field]},en-default`;
    } else {
      queryParameters[`fields.${key}`] = query[field];
    }
  }

  try {
    const response = await fetchWithRetry(
      `${endpoint}/?${stringify(queryParameters)}`
    );

    const resource = await response.json();

    const parsedResource = contentfulResolveResponse(resource);

    return parsedResource as T[];
  } catch (e) {
    // We may wish to have this return an empty array of T[]
    throw e;
  }
};

export default fetchResource;

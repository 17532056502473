import fetchContentWithProducts, {
  NOT_FOUND,
} from 'lib/contentful/fetchContentWithProducts';
import checkIfRestrictedRoute from 'lib/restrictedRoutes';
import { sendReport } from 'lib/sendReport';
import { toContentPage } from 'models/contentPages/serializers';
import type { ContentPageModel } from 'models/contentPages/types';
import { localeModel } from 'models/locales/types';
import type { GetServerSideProps } from 'next';
import absoluteUrl from 'next-absolute-url';

const fetchContentPage: GetServerSideProps<
  ContentPageModel & { origin: string }
> = async ({ req, res, query, params }) => {
  if (!params?.doNotCache) {
    res.setHeader('Cache-Control', 'public, max-age=60');
  }

  if (!localeModel.is(query.locale)) {
    return {
      notFound: true,
    };
  }

  const locale = query.locale;

  const { origin } = absoluteUrl(req, 'localhost:8080');

  try {
    const resource = await fetchContentWithProducts({
      locale,
      slug: query.products as string,
    });

    const contentPage = toContentPage({
      ...resource,
      locale,
    });

    if (!contentPage) {
      throw new Error('Content page data is invalid');
    }

    return {
      props: {
        ...contentPage,
        origin,
      },
    };
  } catch (e) {
    if ((e as Error).message.startsWith(NOT_FOUND)) {
      return { notFound: true };
    }

    sendReport(e);

    throw e;
  }
};

export default checkIfRestrictedRoute(fetchContentPage);

/* istanbul ignore file */
import { createApiBuilderFromCtpClient } from '@commercetools/platform-sdk';
import SdkAuth from '@commercetools/sdk-auth';
import { ClientBuilder } from '@commercetools/sdk-client-v2';

interface Arguments {
  authHost: string;
  httpHost: string;
  projectKey: string;
  clientId: string;
  clientSecret: string;
}

export const createCommerceToolsApiClient = ({
  authHost,
  httpHost,
  projectKey,
  clientId,
  clientSecret,
}: Arguments) => {
  const client = new ClientBuilder()
    .withClientCredentialsFlow({
      host: authHost,
      projectKey,
      credentials: {
        clientId,
        clientSecret,
      },
      fetch: fetch,
    })
    .withHttpMiddleware({
      host: httpHost,
      fetch: fetch,
    })
    .build();

  return createApiBuilderFromCtpClient(client).withProjectKey({
    projectKey,
  });
};

export const createCommerceToolsAuthClient = ({
  authHost,
  projectKey,
  clientId,
  clientSecret,
}: Omit<Arguments, 'httpHost'>): SdkAuth =>
  new SdkAuth({
    host: authHost,
    projectKey,
    credentials: {
      clientId,
      clientSecret,
    },
    scopes: [
      `create_anonymous_token:${projectKey}`,
      `view_products:${projectKey}`,
      `manage_my_orders:${projectKey}`,
      `manage_my_profile:${projectKey}`,
      `manage_my_payments:${projectKey}`,
    ],
  });

import { generateAnonymousId } from 'store/auth/thunks';
import { retrieveOrCreateCart } from 'store/cart/thunks';
import { StoreApi } from 'store/types';

export const onBeforeLift = (store: StoreApi, locale: string) => () => {
  const { dispatch, getState } = store;
  const { cart, auth } = getState();

  // The presence of a refresh token indicates that a user
  // has a session in progress. If they don't, generate an
  // anonymous ID for use with the Graphene API and cart.
  if (cart.id) {
    dispatch(retrieveOrCreateCart(locale));
  } else if (!auth.refreshToken) {
    dispatch(generateAnonymousId());
  }
};
